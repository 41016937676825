import { createFileRoute, redirect } from '@tanstack/react-router';
import posthog from 'posthog-js';
import { z } from 'zod';

import {
  getCandidateGoalsOptions,
  postCandidateTasksFilterOptions,
} from 'client/@tanstack/react-query.gen';

const schema = z.object({
  module: z.string().optional(),
  task: z.string().optional(),
});

export type SearchSchema = z.infer<typeof schema>;

export const Route = createFileRoute(
  '/_private/_route/_internal/candidates/$candidateId/tasks/',
)({
  beforeLoad: ({ params }) => {
    if (!posthog.isFeatureEnabled('project-account-plan')) {
      throw redirect({
        params: {
          candidateId: params.candidateId,
        },
        replace: true,
        to: '/candidates/$candidateId',
      });
    }
  },
  loader: async ({ context, params }) => {
    const { queryClient } = context;
    const pGoals = queryClient.ensureQueryData(
      getCandidateGoalsOptions({
        path: {
          candidate_uuid: params.candidateId,
        },
      }),
    );
    const pTasks = queryClient.ensureQueryData(
      postCandidateTasksFilterOptions({
        body: {
          page: 1,
          page_size: 100,
        },
        path: {
          candidate_uuid: params.candidateId,
        },
      }),
    );
    await Promise.all([pGoals, pTasks]);
  },
  validateSearch: schema,
});
