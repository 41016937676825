import { createFileRoute, redirect } from '@tanstack/react-router';
import posthog from 'posthog-js';

import { getPeopleCurrentOptions } from 'client/@tanstack/react-query.gen';

export const Route = createFileRoute('/_private/_auth/signup/')({
  beforeLoad: async ({ context }) => {
    const { queryClient } = context;
    const pUser = queryClient.ensureQueryData(getPeopleCurrentOptions());
    const [person] = await Promise.all([pUser]);

    // @ts-ignore
    const metadata = window.Clerk?.user?.publicMetadata || {};

    if (posthog.isFeatureEnabled('project-clerk')) {
      if (
        'onboarding_complete' in metadata &&
        metadata.onboarding_complete === true
      ) {
        throw redirect({ to: '/' });
      }
    } else if (!person.newly_invited) {
      throw redirect({ to: '/' });
    }
  },
});
