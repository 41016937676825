import { useAuth } from '@clerk/clerk-react';
import * as Sentry from '@sentry/react';
import { useProfiler } from '@sentry/react';
import type { QueryClient } from '@tanstack/react-query';
import {
  createRootRouteWithContext,
  Outlet,
  useLocation,
  useRouter,
} from '@tanstack/react-router';
import { SpeedInsights } from '@vercel/speed-insights/react';
import localforage from 'localforage';
import { DateTime } from 'luxon';
import posthog from 'posthog-js';
import { useEffect, useRef } from 'react';

import {
  getPeopleCurrentOptions,
  getTeamOptions,
} from 'client/@tanstack/react-query.gen';
import type { AccessToken, Person, PersonRole } from 'client/types.gen';
import { useAxiosAuthInterceptor } from 'helpers/auth';
import { captureOkr } from 'helpers/logging';
import { getPersonRole } from 'helpers/person';
import { lazy } from 'helpers/react';
import { useYup } from 'helpers/yup';

const ErrorPage = lazy(() => import('pages/Error'));

// Automatically refresh page for users who may be leaving tabs open forever.
// The threshold value should be set high enough to avoid affecting majority
// of regular users.
const windowForceRefreshIntervalInSeconds =
  import.meta.env.VITE_APP_WINDOW_FORCE_REFRESH_INTERVAL_IN_SECONDS || 0;

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null
    : lazy(() =>
        import('@tanstack/router-devtools').then((response) => ({
          default: response.TanStackRouterDevtools,
        })),
      );

interface RouterContext {
  queryClient: QueryClient;
  role: PersonRole | undefined;
  user: Person | undefined;
}

export const Route = createRootRouteWithContext<RouterContext>()({
  beforeLoad: async ({ context, params }) => {
    const { queryClient } = context;

    // @ts-ignore
    const session = window.Clerk?.session;

    let user;

    try {
      const token = await (posthog.isFeatureEnabled('project-clerk')
        ? session?.getToken()
        : localforage.getItem<AccessToken>('atomToken'));

      if (token) {
        user = await queryClient.ensureQueryData(getPeopleCurrentOptions());
      }
    } catch (error) {
      Sentry.captureException(error);
      if (posthog.isFeatureEnabled('project-clerk')) {
        await session?.end?.();
      } else {
        await localforage.removeItem('atomToken');
      }
      queryClient.clear();
    }

    if (!user) {
      return { role: undefined, user };
    }

    const teamId = 'teamId' in params ? params.teamId : undefined;

    const currentTeam = teamId
      ? await queryClient.ensureQueryData(
          getTeamOptions({ path: { team_slug: teamId as string } }),
        )
      : undefined;

    const role = getPersonRole(
      user,
      currentTeam ? currentTeam.slug : undefined,
    );

    return { role, user };
  },
  component: RootComponent,
  errorComponent: ErrorPage,
});

function RootComponent() {
  useProfiler('App');
  useYup();

  useAxiosAuthInterceptor();

  const auth = useAuth();
  const router = useRouter();
  const location = useLocation();

  const { user } = Route.useRouteContext();

  const refSessionStart = useRef<DateTime>();

  useEffect(() => {
    if (windowForceRefreshIntervalInSeconds > 0) {
      refSessionStart.current = DateTime.fromISO(new Date().toISOString()).plus(
        { seconds: windowForceRefreshIntervalInSeconds },
      );
    }
  }, []);

  useEffect(() => {
    const threshold = refSessionStart.current;
    if (threshold && user?.id) {
      const now = DateTime.fromISO(new Date().toISOString());
      if (now > threshold) {
        captureOkr('refresh_due_to_stale_client', {
          timeout_seconds: windowForceRefreshIntervalInSeconds,
          user_id: user.id,
        });
        window.location.reload();
      }
    }
  }, [location.pathname, user?.id]);

  useEffect(() => {
    posthog.capture('$pageview');
  }, [location]);

  useEffect(() => {
    router.invalidate();
  }, [auth.isSignedIn, router]);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <SpeedInsights
        debug={process.env.NODE_ENV !== 'production'}
        route={location.pathname}
        sampleRate={1.0}
      />
      <Outlet />
      <TanStackRouterDevtools />
    </Sentry.ErrorBoundary>
  );
}
