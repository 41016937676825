import type {
  DefaultError,
  InfiniteData,
  QueryClient,
  QueryKey,
  QueryOptions,
} from '@tanstack/react-query';

export const updateQueriesData = async <Data, TPageParam>({
  queryClient,
  queryKey,
  updater,
}: Pick<QueryOptions<Data, DefaultError, Data, any, TPageParam>, 'queryFn'> & {
  queryClient: QueryClient;
  queryKey: QueryKey;
  updater: (state: Data, index?: number) => Data;
}) => {
  const cached = queryClient
    .getQueriesData({
      queryKey,
    })
    .filter(([, data]) => Boolean(data));

  // no cached query means it might be loading
  if (!cached.length) {
    return;
  }

  await queryClient.cancelQueries({ queryKey });

  queryClient.setQueriesData<InfiniteData<Data> | Data>(
    {
      queryKey,
    },
    (oldData) => {
      if (!oldData) {
        return oldData;
      }

      // infinite query
      if (typeof oldData === 'object' && 'pages' in oldData) {
        return {
          ...oldData,
          pages: oldData.pages.map((page, index) => updater(page, index)),
        };
      }

      // regular query
      return updater(oldData, 0);
    },
  );
};
