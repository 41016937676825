import { createFileRoute, redirect } from '@tanstack/react-router';
import posthog from 'posthog-js';

export const Route = createFileRoute('/_private/_route/user/teams/')({
  beforeLoad: () => {
    if (posthog.isFeatureEnabled('project-clerk')) {
      throw redirect({ to: '/user' });
    }
  },
});
