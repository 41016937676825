import { useAuth } from '@clerk/clerk-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import localforage from 'localforage';
import posthog from 'posthog-js';
import { useCallback } from 'react';
import { useResetRecoilState } from 'recoil';

import { getPeopleFindOptions } from 'client/@tanstack/react-query.gen';
import type {
  GetPeopleFindData,
  GetPeopleFindResponse,
  Person,
} from 'client/types.gen';
import { atomForm, atomModal, atomRecommendations } from 'config/atoms';
import { setUser } from 'helpers/logging';
import { useQueryData, useSearch } from 'helpers/search';
import type { Candidate, CandidateHighlightSelectable } from 'types/candidate';
import type { UseSearch } from 'types/search';

export const defaultCountry = 'United States';

export const getNameInitial = (name: string) => name.slice(0, 1);

export const getPersonName = (
  person?:
    | Partial<Pick<Person, 'first_name' | 'last_name'>>
    | Partial<Pick<Candidate, 'candidate_name'>>
    | null,
) => {
  if (!person) {
    return '';
  }

  if ('candidate_name' in person && person.candidate_name) {
    return person.candidate_name;
  }

  // TODO: Kept for legacy API compatibility
  if ('person_name' in person && person.person_name) {
    return person.person_name as string;
  }

  const firstName = ('first_name' in person ? person.first_name : '') ?? '';
  const lastName = ('last_name' in person ? person.last_name : '') ?? '';

  return lastName ? `${firstName} ${lastName}` : firstName;
};

export const getPersonRole = (person?: Person, teamSlug?: string) => {
  if (!person) {
    return undefined;
  }

  const adminRole = isInternalPerson(person);

  if (adminRole) {
    return adminRole;
  }

  if (!teamSlug) {
    return person.roles?.[0];
  }

  return person.roles?.find((role) => role.team_slug === teamSlug);
};

export const getRolesMoveTeam = (
  roles: Required<Person>['roles'],
  teamSlug: string,
  index: number,
): Required<Person>['roles'] => {
  let team: Required<Person>['roles'][number];

  const rolesWithoutTeam = roles.filter((item) => {
    if (item.team_slug === teamSlug) {
      team = item;
      return false;
    }
    return true;
  });

  if (index === -1) {
    return rolesWithoutTeam;
  }

  return [
    ...rolesWithoutTeam.slice(0, index),
    team!,
    ...rolesWithoutTeam.slice(index),
  ];
};

export const isInternalPerson = (person?: Person) =>
  (person?.roles ?? []).find((role) => role.title === 'Banff Admin');

export const normalizePersonHighlights = (
  highlights: Partial<CandidateHighlightSelectable>[],
) =>
  highlights
    .filter((item) => item.highlight)
    .map((item) => {
      const highlight: Partial<CandidateHighlightSelectable> = {
        highlight: item.highlight ?? '',
      };
      if (typeof item.selected === 'boolean') {
        highlight.selected = item.selected;
      }
      if (item.uuid) {
        highlight.uuid = item.uuid;
      }
      return highlight;
    });

export const showPostalCode = (country?: string) => country === defaultCountry;

export const useOnSuccessLogOut = () => {
  const queryClient = useQueryClient();
  const auth = useAuth();

  const resetForm = useResetRecoilState(atomForm);
  const resetModal = useResetRecoilState(atomModal);
  const resetRecommendations = useResetRecoilState(atomRecommendations);

  const onSuccessLogOut = useCallback(async () => {
    if (posthog.isFeatureEnabled('project-clerk')) {
      auth.signOut();
    } else {
      await localforage.removeItem('atomToken');
      queryClient.invalidateQueries({
        queryKey: [{ scope: 'auth' }] as const,
      });
    }
    // TODO: We reset every state except `redirectIgnore`.
    // There should be an API to abstract resetting, Recoil is working on memory management.
    // learn more: https://github.com/facebookexperimental/Recoil/issues/387#issuecomment-658297951
    resetForm();
    resetModal();
    resetRecommendations();
    setUser();
    queryClient.clear();
    // Causes an ugly/jarring experience when logging out.
    // Hopefully no longer needed.
    if (!posthog.isFeatureEnabled('project-clerk')) {
      window.location.reload();
    }
  }, [queryClient, resetForm, resetModal, resetRecommendations, auth]);

  return {
    onSuccessLogOut,
  };
};

export const useSearchPeople: UseSearch<
  GetPeopleFindResponse,
  Omit<GetPeopleFindData, 'url'>
> = (opts, p) => {
  const { debouncedPhrase, enabled, phrase, ...search } = useSearch(opts);
  const query = useQuery({
    enabled,
    ...getPeopleFindOptions({
      ...p,
      query: {
        ...p?.query,
        phrase: opts?.debounce ? debouncedPhrase : phrase,
      },
    }),
  });
  const queryDataProps = useQueryData({
    ...opts,
    phrase,
    predicate: (person) => person.id,
    query,
  });

  return {
    ...query,
    ...search,
    ...queryDataProps,
    debouncedPhrase,
    phrase,
  };
};
