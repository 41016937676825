import { createFileRoute, redirect } from '@tanstack/react-router';
import posthog from 'posthog-js';

import { getPeopleCurrentOptions } from 'client/@tanstack/react-query.gen';

export const Route = createFileRoute('/_private/_route')({
  beforeLoad: async ({ context }) => {
    const { queryClient } = context;
    const pUser = queryClient.ensureQueryData(getPeopleCurrentOptions());
    const [person] = await Promise.all([pUser]);

    if (posthog.isFeatureEnabled('project-clerk')) {
      // @ts-ignore
      const metadata = window.Clerk?.user?.publicMetadata || {};

      if (
        'onboarding_complete' in metadata &&
        metadata.onboarding_complete === false
      ) {
        throw redirect({ replace: true, to: '/signup' });
      }
    } else {
      if (person.newly_invited) {
        throw redirect({ replace: true, to: '/signup' });
      }

      if (person.has_forgotten_password) {
        throw redirect({ replace: true, to: '/set-password' });
      }
    }
  },
});
