import type { CreateClientConfig } from '@hey-api/client-axios';
import { jwtDecode } from 'jwt-decode';
import localforage from 'localforage';
import posthog from 'posthog-js';
import queryString from 'query-string';

import { postToken } from 'client/sdk.gen';
import type { AccessToken } from 'client/types.gen';

// cache ongoing refresh request
let refreshTokenPromise: Promise<string | undefined> | null = null;

export const createClientConfig: CreateClientConfig = (config) => ({
  ...config,
  auth: async () => {
    // TODO: a less gross way to get a reference to the Clerk singleton outside
    // of React. (https://github.com/orgs/clerk/discussions/1751)
    if (posthog.isFeatureEnabled('project-clerk')) {
      // @ts-ignore
      const token = await window.Clerk?.session?.getToken();
      return token || undefined;
    }

    // TODO: remove after project-clerk
    const token = await localforage.getItem<AccessToken>('atomToken');
    if (!token) {
      return undefined;
    }

    const { exp } = jwtDecode(token.access_token);
    const currentTime = Math.floor(Date.now() / 1000);
    const bufferTime = 30; // in seconds

    if (exp && exp - bufferTime > currentTime) {
      return token.access_token;
    }

    // handle token refresh, avoid duplicate requests
    if (!refreshTokenPromise) {
      const refreshFn = async () => {
        const { data: newToken, error } = await postToken({
          body: {
            expired_token: token.access_token,
            grant_type: 'refresh_token',
            refresh_token: token.refresh_token,
          },
        });

        if (error) {
          await localforage.removeItem('atomToken');
          window.location.reload();
          return undefined;
        }

        refreshTokenPromise = null;
        await localforage.setItem('atomToken', newToken);
        return newToken?.access_token;
      };

      refreshTokenPromise = refreshFn();
    }

    // return the cached promise so all callers share the result
    return refreshTokenPromise;
  },
  baseURL: import.meta.env.VITE_APP_BASE_URL || '',
  paramsSerializer: (params) => queryString.stringify(params),
});
