import { ClerkLoaded } from '@clerk/clerk-react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import localforage from 'localforage';
import posthog from 'posthog-js';
import queryString from 'query-string';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { routeTree } from './routeTree.gen';

import { client } from 'client/client.gen';
import type { AccessToken } from 'client/types.gen';
import { AppProvider } from 'components/AppProvider';
import { AxiosAuthSubscriber } from 'components/AxiosAuthSubscriber';
import * as serviceWorker from 'config/serviceWorker';
import { queryClient } from 'helpers/query';
import { generateSearch } from 'helpers/router';

// CSS reset, needed to hide some buttons for example
// learn more: https://github.com/ant-design/ant-design/issues/38732
import 'antd/dist/reset.css';

import 'config/i18n';
import 'config/logging';
import 'config/yup';

import 'config/styles.css';
import 'config/tailwind.css';

const container = document.getElementById('root');
const root = createRoot(container!);

client.instance.interceptors.response.use(async (response) => {
  if (posthog.isFeatureEnabled('project-clerk')) {
    return response;
  }

  if (response.data && typeof response.data === 'object') {
    const token = response.data as AccessToken;
    // check if response has token shape
    if (
      token.token_type === 'bearer' &&
      token.access_token &&
      token.refresh_token
    ) {
      await localforage.setItem('atomToken', token);
      queryClient.invalidateQueries();
      router.invalidate();
    }
  }
  return response;
});

const router = createRouter({
  context: {
    queryClient,
    role: undefined,
    user: undefined,
  },
  defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  notFoundMode: 'fuzzy',
  parseSearch: (query) => queryString.parse(query),
  routeTree,
  stringifySearch: (search) =>
    generateSearch(search, {
      arrayFormat: 'none',
    }),
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

root.render(
  <StrictMode>
    <AppProvider>
      <ReactQueryDevtools buttonPosition="bottom-right" position="bottom" />
      <AxiosAuthSubscriber />
      <ClerkLoaded>
        <RouterProvider router={router} />
      </ClerkLoaded>
    </AppProvider>
  </StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
