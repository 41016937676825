import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_layoutAuth/_guest')({
  beforeLoad: async ({ context, search }) => {
    const { user } = context;

    if (user) {
      const continuePath: string | undefined =
        'continue' in search ? (search.continue as string) : undefined;
      throw redirect({ replace: true, to: continuePath ?? '/' });
    }
  },
  component: Outlet,
});
